import React, { Fragment, lazy } from 'react';
import { Route } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { MainLayout } from 'layout';
import { InteractionType } from '@azure/msal-browser';
import { scopes } from 'config/authConfig';
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';

const routes = [
    {
        exact: true,
        secured: true,
        path: '/',
        layout: MainLayout,
        component: Home,
    },
    {
        exact: true,
        secured: false,
        path: '/404',
        component: lazy(() => import('pages/NotFound')),
    },
    {
        exact: true,
        secured: false,
        path: '/hibas-bejelentkezes',
        component: lazy(() => import('pages/InvalidLoginPage')),
    },
    {
        exact: true,
        secured: true,
        path: '/fooldal',
        layout: MainLayout,
        component: lazy(() => import('pages/Home')),
    },
    {
        exact: true,
        secured: true,
        path: '/butorok',
        layout: MainLayout,
        component: lazy(() => import('pages/FurnituresManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/midibi-butorok',
        layout: MainLayout,
        component: lazy(() => import('pages/MidibiFurnitureManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/butorszerkesztes',
        layout: MainLayout,
        component: lazy(() => import('pages/FurnitureConfigurator')),
    },
    {
        exact: true,
        secured: true,
        layout: MainLayout,
        path: '/szerkeszto-konfiguralas',
        component: lazy(() => import('pages/FurnitureEditor')),
    },
    {
        exact: true,
        secured: true,
        path: '/szinek',
        layout: MainLayout,
        component: lazy(() => import('pages/ColorsManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/uzletek',
        layout: MainLayout,
        component: lazy(() => import('pages/StoresManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/uzletszerkesztes',
        layout: MainLayout,
        component: lazy(() => import('pages/StoreEditor')),
    },
    {
        exact: true,
        secured: true,
        path: '/insight-adatok',
        layout: MainLayout,
        component: lazy(() => import('pages/InsightDataManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/rendelesek',
        layout: MainLayout,
        component: lazy(() => import('pages/OrdersManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/rendeles',
        layout: MainLayout,
        component: lazy(() => import('pages/OrderDetails')),
    },
    {
        exact: true,
        secured: true,
        path: '/insight-arazas-logok',
        layout: MainLayout,
        component: lazy(() => import('pages/FurniturePriceLogsManager')),
    },
    {
        exact: true,
        secured: true,
        path: '/minimum-arresek-kezelese',
        layout: MainLayout,
        component: lazy(() => import('pages/MinimumPricing')),
    },
    {
        exact: true,
        secured: true,
        path: '/akcio',
        layout: MainLayout,
        component: lazy(() => import('pages/DiscountEditor')),
    },
    // {
    //     path: '*',
    //     routes: [
    //         {
    //             exact: true,
    //             path: '/',
    //             component: Home,
    //         },
    //         {
    //             component: () => <Navigate to="/404" />,
    //         },
    //     ],
    // },
];

export const renderRoutes = () => (
    <>
        <Route path="*" element={<NotFound />} />
        {routes.map((route, i) => {
            const Layout = route.layout || Fragment;
            const Component = route.component || Fragment;

            if (route.secured) {
                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        element={
                            <MsalAuthenticationTemplate
                                interactionType={InteractionType.Redirect}
                                authenticationRequest={{ scopes: scopes }}
                            >
                                <Layout>
                                    <Component />
                                </Layout>
                            </MsalAuthenticationTemplate>
                        }
                    />
                );
            }

            return (
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                        <div>
                            <Layout>
                                <Component />
                            </Layout>
                        </div>
                    }
                />
            );
        })}
    </>
);

export default routes;

import React from 'react';
import {
    HomeOutlined,
    DeckOutlined,
    StoreOutlined,
    ColorLensOutlined,
    CableOutlined,
    ShoppingCartOutlined,
    PriceCheckOutlined,
    AttachMoneyOutlined,
} from '@mui/icons-material';
import { ApplicationRole } from 'models/enums/applicationRole';

export default [
    {
        id: 0,
        roles: [ApplicationRole.MidibiAdministrator, ApplicationRole.StoreAdministrator],
        companies: [],
        title: 'Főoldal / Analitika',
        href: '/fooldal',
        icon: <HomeOutlined />,
    },
    {
        id: 1,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: [],
        title: 'Bútorok kezelése',
        href: '/butorok',
        icon: <DeckOutlined />,
    },
    {
        id: 2,
        roles: [ApplicationRole.StoreAdministrator],
        companies: [],
        title: 'MIDIBI bútorok',
        href: '/midibi-butorok',
        icon: <DeckOutlined />,
    },
    {
        id: 3,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: [],
        title: 'Üzletek kezelése',
        href: '/uzletek',
        icon: <StoreOutlined />,
    },
    {
        id: 4,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: [],
        title: 'Színek kezelése',
        href: '/szinek',
        icon: <ColorLensOutlined />,
    },
    {
        id: 5,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: ['divian'],
        title: 'Insight adatok',
        href: '/insight-adatok',
        icon: <CableOutlined />,
    },
    {
        id: 6,
        roles: [ApplicationRole.MidibiAdministrator, ApplicationRole.StoreAdministrator],
        companies: [],
        title: 'Rendelések',
        href: '/rendelesek',
        icon: <ShoppingCartOutlined />,
    },
    {
        id: 7,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: ['divian'],
        title: 'Insight Árazás Logok',
        href: '/insight-arazas-logok',
        icon: <PriceCheckOutlined />,
    },
    {
        id: 8,
        roles: [ApplicationRole.MidibiAdministrator],
        companies: [],
        title: 'Minimum árrések',
        href: '/minimum-arresek-kezelese',
        icon: <AttachMoneyOutlined />,
    },
];
